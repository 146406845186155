<template>
    <standard-page sub_title="Decentralized Identifier" v-bind:title="claim ? claim.description: 'Claim Attachments & Attestations'">
        <template v-slot:breadcrumb>
            <span><router-link :to="{ name: 'identities-home'}">Identifier</router-link></span>
            <span><router-link :to="{ name: 'identities-catalogs'}">Catalogs</router-link></span>
            <span><router-link :to="{ name: 'identities-catalogs-dids'}">{{catalogid}}</router-link></span>
            <span><router-link :to="{ name: 'viewdid'}">{{did | hex_format(8, '.....')}}</router-link></span>
            <span><router-link :to="{ name: 'viewdid'}">Claims</router-link></span>
            <span>Claims: {{claim? claim.description : claimid}}</span>
        </template>
        <template v-slot:sub-title>
            <p class="mg-b-10">
                <a href="javascript:void(0);" v-clipboard:copy="did" v-clipboard:success="copied">
                    {{did | did}}
                </a>
            </p>
        </template>

        <template v-slot:content>
            <div class="row row-sm mg-t-20 mg-b-20">
                <div class="col-12">
                    <div class="az-content-label">Claim Attachments</div>
                    <p v-html="$DEFINITIONS.claimAttachment"></p>
                </div>
            </div>

            <div class="table-responsive" v-if="claim_attachments.length >0">
                <table class="table table-bordered table-striped mg-b-0">
                    <thead>
                    <tr>
                        <th class="tx-left">Name</th>
                        <th class="tx-left">File Name</th>
                        <th class="tx-left">File Hash</th>
                        <th class="tx-left">Created On</th>
                        <th class="tx-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-bind:key="index" v-for="(c, index) in claim_attachments">
                        <td class="masked">{{c.property_name}}</td>
                        <td class="masked">{{c.name}}</td>
                        <td class="masked">{{c.hash | hex_format(10, '.....')}}</td>
                        <td class="masked">{{c.created | timestamp}}</td>
                        <td class="masked">
                                <span @click="downloadAttachment(c)" class="text-decoration-none text-info download mg-l-10" style="cursor: pointer">
                                    <i class="fas fa-download fa-lg" title="Download attachment"></i>
                                </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="mg-t-50" v-else>
                <h4 class="card-title text-muted text-center">No records found</h4>
            </div>
            <!--        <hr>-->

            <div class="row row-sm mg-t-50 mg-b-20">
                <div class="col-12">
                    <div class="az-content-label">Claim Verifications</div>
                    <p v-html="$DEFINITIONS.claimAttestation"></p>
                </div>
            </div>
            <div class="table-responsive" v-if="verifications.length >0">
                <table class="table table-bordered table-striped mg-b-0">
                    <thead>
                    <tr>
                        <th class="tx-left">#</th>
                        <th class="tx-left">Verified By</th>
                        <th class="tx-left">Verified On</th>
                        <th class="tx-left">Transaction</th>
                        <th class="tx-left">Expiry</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-bind:key="index" v-for="(verification, index) in filterd_verifications">
                        <td class="masked">{{index+1}}</td>
                        <td>{{verification.verified_by}}</td>
                        <td>{{verification.created | timestamp}}</td>
                        <extrinsic-link :extrinsic="verification.extrinsic"></extrinsic-link>
                        <td>{{verification.expiry | timestamp}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="mg-t-50" v-else>
                <h4 class="card-title text-muted text-center">No records found</h4>
            </div>
        </template>
    </standard-page>
</template>

<script>
    import _ from "lodash";

    export default {
        name: "ViewClaim",
        props: ['catalogid', 'did', 'claimid'],
        data() {
            return {
                claim: null,
                verifications: [],
                kyc_provider_pub_keys: [],
                filterd_verifications: [],
                pages: 1,
                claim_attachments: []
            };
        },
        mounted() {
            this.$root.$on('refreshAttestation', () => {
                this.init();
            });
            this.init();
        },
        methods: {
            async init() {
                await Promise.all([
                    this.getClaim(),
                    this.getVerificationDetails(),
                    this.getClaimAttachments()
                ])
            },
            async getClaim() {
                try {
                    let {data} = await this.$identity.get(`/identity/dids/${this.did}/claims/${this.claimid}`);
                    this.claim = data;
                } catch (e) {

                }
            },
            async getClaimAttachments() {
                try {
                    let {data} = await this.$identity.get(`/identity/dids/${this.did}/claims/${this.claimid}/documents`);
                    this.claim_attachments = data.claim_attachment_list;
                } catch (e) {

                }
            },
            async getVerificationDetails() {
                if (typeof this.claimid != 'undefined' && this.claimid !== null) {
                    this.verifications = [];
                    try {
                        let params = {
                            page: 0,
                            per_page: 1000
                        };
                        let reply = await this.$identity.get(`/identity/dids/${this.did}/claims/${this.claimid}/attestations`, {params});
                        this.verifications = reply.data ? reply.data.attestations : [];
                        this.kyc_provider_pub_keys = _.map(this.verifications, (verification) => {
                            return verification.verified_by;
                        });
                    } catch (e) {
                        this.$toastr.e("Error retrieving Verification Details", "Error");
                        console.log(e);
                    }
                    await this.getOrgsByPubKey();
                }
            },
            async getOrgsByPubKey() {
                this.filterd_verifications = [];
                try {
                    let reply = await this.$accounts.post(`/accounts/org/by_public_keys`, {
                        pub_keys: this.kyc_provider_pub_keys
                    });
                    let orgs_by_pub_key = reply.data ? reply.data.orgs : [];
                    _.forEach(this.verifications, (verification) => {
                        let filtered_org = _.filter(orgs_by_pub_key, {pub_key: verification.verified_by});
                        verification.verified_by = filtered_org[0].name + " <" + filtered_org[0].email + ">";
                        this.filterd_verifications.push(verification);
                    });
                } catch (e) {
                }
            },
            copied() {
                this.$toastr.i("Copied to clipboard", "Copied");
            },
            async downloadAttachment(attachment) {
                let {data} = await this.$identity.get(`identity/dids/${this.did}/claims/${this.claimid}/documents/${attachment.id}`);
                let link = document.createElement('a');
                link.href = 'data:application/octet-stream;base64,' + data;
                link.download = attachment.name;
                link.click();
            },
        }
    }
</script>

<style scoped>

</style>